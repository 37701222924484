import { BetaPill, Row } from "@gadgetinc/widgets";
import { ActionsDocIcon } from "@gadgetinc/widgets/src/icons/ActionsDocIcon";
import { DataDocIcon } from "@gadgetinc/widgets/src/icons/DataDocIcon";
import { EnvironmentsDocIcon } from "@gadgetinc/widgets/src/icons/EnvironmentsDocIcon";
import { FrontendsDocIcon } from "@gadgetinc/widgets/src/icons/FrontendsDocIcon";
import { GettingStartedIcon } from "@gadgetinc/widgets/src/icons/GettingStartedIcon";
import { ModelsDocIcon } from "@gadgetinc/widgets/src/icons/ModelsDocIcon";
import { PluginsDocIcon } from "@gadgetinc/widgets/src/icons/PluginsDocIcon";
import { RoutesDocIcon } from "@gadgetinc/widgets/src/icons/RoutesDocIcon";
import { SourceControlDocsIcon } from "@gadgetinc/widgets/src/icons/SourceControlDocsIcon";
import { SparkleIcon } from "@gadgetinc/widgets/src/icons/SparkleIcon";
import { ToolsDocIcon } from "@gadgetinc/widgets/src/icons/ToolsDocIcon";
import { styled } from "baseui";
import { compact } from "lodash";
import React from "react";
import type { LegacyEnvironmentName } from "state-trees/src/Environment";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import { getNamespacedApiIdentifier } from "state-trees/src/utils";
import { ActionsAsCodeDocsVersion, DocsVersions, MultiEnvironmentDocsVersion, type FullMetaBlob } from "../FullMetaBlob";
import { currentDocsVersion } from "./DocsVersionSelector";
import type { GadgetItemProps, NavItem, PageLevelHeading } from "./navigation-types";
import { GadgetOnlyHeader } from "./navigation-types";

const Code = styled("span", ({ $theme }) => ({
  fontFamily: $theme.typography.MonoHeadingMedium.fontFamily,
}));

const ActionsAsCodeGuidesHeadings = (flags: GadgetFlags, currentApp: FullMetaBlob): PageLevelHeading[] => [
  {
    nodeName: "H1",
    title: "Getting started",
    icon: <GettingStartedIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/getting-started`,
    subNav: [
      {
        nodeName: "H2",
        title: "Quickstart",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/getting-started/quickstart`,
      },
      {
        nodeName: "H2",
        title: "What is Gadget?",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/getting-started/what-is-gadget`,
      },
      {
        nodeName: "H2",
        title: "How to build a Gadget app",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/getting-started/how-to-build-a-gadget-app`,
      },
      {
        nodeName: "H2",
        title: "How is Gadget different from X",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/getting-started/how-is-gadget-different-from-x`,
      },
      {
        title: "Tutorials",
        nodeName: "H1",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials`,
        subNav: [
          {
            nodeName: "H1",
            title: "Web apps + AI",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/web-ai`,
            subNav: [
              // remove blog tutorial from nav until updated with email/pw auth
              // {
              //   nodeName: "H1",
              //   title: "Blog with authentication",
              //   itemId: `${ActionsAsCodeVersion.prefix}/guides/tutorials/blog-with-auth`,
              // },
              {
                nodeName: "H1",
                title: "OpenAI screenwriter",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/ai-screenwriter`,
              },
              {
                nodeName: "H1",
                title: "Subscribe to 3rd party webhooks",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/webhook-subscription`,
              },
            ],
          },
          {
            nodeName: "H1",
            title: "Shopify",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/shopify`,
            subNav: [
              {
                nodeName: "H1",
                title: "Connecting to Shopify",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/connecting-to-shopify`,
              },
              {
                nodeName: "H1",
                title: "Automated product tagger",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/automated-product-tagger`,
              },
              {
                nodeName: "H1",
                title: "Pre-purchase checkout UI extension",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/checkout-ui-extension`,
              },
              {
                nodeName: "H1",
                title: "Use Gadget as middleware",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/middleware`,
              },
              {
                nodeName: "H1",
                title: "Storefront chatbot (OpenAI product recommender)",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/ai-product-recommender`,
              },
              {
                nodeName: "H1",
                title: "Product quiz (Shopify theme extensions and Liquid storefronts)",
                itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/tutorials/product-recommendation-quiz-app`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Models",
    icon: <ModelsDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/models`,
    subNav: [
      {
        nodeName: "H2",
        title: "Fields",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/models/fields`,
      },
      {
        nodeName: "H2",
        title: "Relationships",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/models/relationships`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Actions",
    icon: <ActionsDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/actions`,
    subNav: [
      {
        nodeName: "H2",
        title: "Types of actions",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/actions/types-of-actions`,
      },
      {
        nodeName: "H2",
        title: "Action code",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/actions/code`,
      },
      {
        nodeName: "H2",
        title: "Triggers",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/actions/triggers`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "HTTP routes",
    icon: <RoutesDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/http-routes`,
    subNav: [
      {
        nodeName: "H2",
        title: "Route structure",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/http-routes/route-structure`,
      },
      {
        nodeName: "H2",
        title: "Route configuration",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/http-routes/route-configuration`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Data access",
    icon: <DataDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/data-access`,
    subNav: [
      {
        nodeName: "H2",
        title: "API access to data",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/data-access/api`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Computed fields <BetaPill>Beta</BetaPill>
          </>
        ),
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/data-access/computed-fields`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Gelly <BetaPill>Beta</BetaPill>
          </>
        ),
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/data-access/gelly`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Frontends",
    icon: <FrontendsDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend`,
    subNav: [
      {
        nodeName: "H2",
        title: "Building frontends",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend/building-frontends`,
      },
      {
        nodeName: "H2",
        title: "Forms",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend/forms`,
      },
      {
        nodeName: "H2",
        title: "Realtime queries",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend/realtime-queries`,
      },
      {
        nodeName: "H2",
        title: "External frontends",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend/external-frontends`,
      },
      {
        nodeName: "H2",
        title: "Optimize LCP",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/frontend/optimize-lcp`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Plugins",
    icon: <PluginsDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins`,
    subNav: [
      {
        nodeName: "H2",
        title: "Shopify",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify`,
        subNav: [
          {
            nodeName: "H2",
            title: "Building Shopify apps",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/building-shopify-apps`,
          },
          {
            nodeName: "H3",
            title: "Working with Shopify webhooks",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/shopify-webhooks`,
          },
          {
            nodeName: "H3",
            title: "Syncing Shopify data",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/syncing-shopify-data`,
          },
          {
            nodeName: "H3",
            title: "Shopify data security",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/data-security`,
          },
          {
            nodeName: "H3",
            title: "Shopify app metafields and metaobjects",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/metafields-metaobjects`,
          },
          {
            nodeName: "H3",
            title: "Shopify app frontends",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/frontends`,
          },
          {
            nodeName: "H3",
            title: "Shopify app OAuth",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/oauth`,
          },
          {
            nodeName: "H3",
            title: "Shopify app billing",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/billing`,
          },
          {
            nodeName: "H3",
            title: "Building Shopify extensions",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/extensions`,
          },
          {
            nodeName: "H3",
            title: "Customer account authentication & customer account UI extensions",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/customer-account-ui-extensions`,
          },
          {
            nodeName: "H3",
            title: "Building with Shopify Polaris",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/polaris`,
          },
          {
            nodeName: "H3",
            title: "Store admin connection",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/admin-connection`,
          },
          {
            nodeName: "H3",
            title: "Shopify API version changelog",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/shopify/api-version-changelog`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "OpenAI",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/openai`,
        subNav: [
          {
            nodeName: "H2",
            title: "Building with OpenAI",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/openai/building-ai-apps`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "Sentry",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/sentry`,
      },
      {
        nodeName: "H2",
        title: "Authentication",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication`,
        subNav: [
          {
            nodeName: "H2",
            title: "Helpers",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication/helpers`,
          },
          {
            nodeName: "H2",
            title: "Workflows",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication/workflows`,
          },
          {
            nodeName: "H2",
            title: "Building with Google OAuth",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication/google-oauth`,
          },
          {
            nodeName: "H2",
            title: "Building with Email/Password",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication/email-pass`,
          },
          {
            nodeName: "H2",
            title: "Removing default authentication methods",
            itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/plugins/authentication/remove-default-auth-methods`,
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Development tools",
    icon: <ToolsDocIcon />,
    itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools`,
    subNav: [
      {
        nodeName: "H1",
        title: "Deployment",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/deployment`,
      },
      {
        nodeName: "H2",
        title: "Logger",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/logger`,
      },
      {
        nodeName: "H2",
        title: "CLI",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/cli`,
      },
      {
        nodeName: "H2",
        title: "Environment variables",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/environment-variables`,
      },
      {
        nodeName: "H1",
        title: "Access control",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/access-control`,
      },
      {
        nodeName: "H1",
        title: "Unit testing",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/unit-testing`,
      },
      {
        nodeName: "H1",
        title: "Storing files",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/storing-files`,
      },
      {
        nodeName: "H1",
        title: "Keyboard shortcuts",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/development-tools/keyboard-shortcuts`,
      },
      {
        nodeName: "H1",
        title: "Account and billing",
        itemId: `${ActionsAsCodeDocsVersion.prefix}/guides/account-and-billing`,
      },
    ],
  },
];

const MutliEnvGuidesHeadings = (flags: GadgetFlags, currentApp: FullMetaBlob): PageLevelHeading[] => [
  {
    nodeName: "H1",
    title: "Getting started",
    icon: <GettingStartedIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started`,
    subNav: [
      {
        nodeName: "H2",
        title: "Quickstart",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/quickstart`,
      },
      {
        nodeName: "H2",
        title: "Connection quickstarts",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/connection-quickstarts`,
        subNav: [
          {
            nodeName: "H2",
            title: "Shopify quickstart",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/shopify-quickstart`,
          },
          {
            nodeName: "H2" as const,
            title: "BigCommerce quickstart",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/bigcommerce-quickstart`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "What is Gadget?",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/what-is-gadget`,
      },
      {
        nodeName: "H2",
        title: "How to build a Gadget app",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/how-to-build-a-gadget-app`,
      },
      {
        nodeName: "H2",
        title: "How is Gadget different from X",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/getting-started/how-is-gadget-different-from-x`,
      },
      {
        title: "Tutorials",
        nodeName: "H1",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials`,
        subNav: [
          {
            nodeName: "H1",
            title: "Web apps + AI",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/web-ai`,
            subNav: [
              // remove blog tutorial from nav until updated with email/pw auth
              // {
              //   nodeName: "H1",
              //   title: "Blog with authentication",
              //   itemId: `${ActionsAsCodeVersion.prefix}/guides/tutorials/blog-with-auth`,
              // },
              {
                nodeName: "H1",
                title: "OpenAI screenwriter",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/ai-screenwriter`,
              },
              {
                nodeName: "H1",
                title: "Subscribe to 3rd party webhooks",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/webhook-subscription`,
              },
            ],
          },
          {
            nodeName: "H1",
            title: "Shopify",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/shopify`,
            subNav: [
              {
                nodeName: "H1",
                title: "Automated product tagger",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/automated-product-tagger`,
              },
              {
                nodeName: "H1",
                title: "Pre-purchase checkout UI extension",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/checkout-ui-extension`,
              },
              {
                nodeName: "H1",
                title: "Forward data to an existing service",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/middleware`,
              },
              {
                nodeName: "H1",
                title: "Storefront chatbot using OpenAI",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/ai-product-recommender`,
              },
              {
                nodeName: "H1",
                title: "Product recommendation quiz",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/product-recommendation-quiz-app`,
              },
            ],
          },
          {
            nodeName: "H2" as const,
            title: "BigCommerce",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/bigcommerce`,
            subNav: [
              {
                nodeName: "H3" as const,
                title: "Automatically add search keywords to products",
                itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/tutorials/bigcommerce/product-search-keywords`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Models",
    icon: <ModelsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/models`,
    subNav: [
      {
        nodeName: "H2",
        title: "Fields",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/models/fields`,
      },
      {
        nodeName: "H2",
        title: "Relationships",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/models/relationships`,
      },
      {
        nodeName: "H2",
        title: "Namespaces",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/models/namespaces`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Actions",
    icon: <ActionsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions`,
    subNav: [
      {
        nodeName: "H2",
        title: "Types of actions",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions/types-of-actions`,
      },
      {
        nodeName: "H2",
        title: "Action code",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions/code`,
      },
      {
        nodeName: "H2",
        title: "Background actions",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions/background`,
      },
      {
        nodeName: "H2",
        title: "Triggers",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions/triggers`,
      },
      {
        nodeName: "H2",
        title: "Namespaces",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/actions/namespaces`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "HTTP routes",
    icon: <RoutesDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/http-routes`,
    subNav: [
      {
        nodeName: "H2",
        title: "Route structure",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/http-routes/route-structure`,
      },
      {
        nodeName: "H2",
        title: "Route configuration",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/http-routes/route-configuration`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Data access",
    icon: <DataDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/data-access`,
    subNav: [
      {
        nodeName: "H2",
        title: "API access to data",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/data-access/api`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Computed fields <BetaPill>Beta</BetaPill>
          </>
        ),
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/data-access/computed-fields`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Gelly <BetaPill>Beta</BetaPill>
          </>
        ),
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/data-access/gelly`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Frontends",
    icon: <FrontendsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend`,
    subNav: [
      {
        nodeName: "H2",
        title: "Building frontends",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/building-frontends`,
      },
      {
        nodeName: "H2",
        title: "Building with Remix",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/remix-in-gadget`,
      },
      {
        nodeName: "H2",
        title: (
          <>
            Autocomponents <BetaPill>Beta</BetaPill>
          </>
        ),
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/autocomponents`,
      },
      {
        nodeName: "H2",
        title: "Forms",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/forms`,
      },
      {
        nodeName: "H2",
        title: "Realtime queries",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/realtime-queries`,
      },
      {
        nodeName: "H2",
        title: "External frontends",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/external-frontends`,
      },
      {
        nodeName: "H2",
        title: "Optimize LCP",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/frontend/optimize-lcp`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Plugins",
    icon: <PluginsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins`,
    subNav: [
      {
        nodeName: "H2",
        title: "Shopify",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify`,
        subNav: [
          {
            nodeName: "H2",
            title: "Building Shopify apps",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/building-shopify-apps`,
          },
          {
            nodeName: "H3",
            title: "Working with Shopify webhooks",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/shopify-webhooks`,
          },
          {
            nodeName: "H3",
            title: "Syncing Shopify data",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/syncing-shopify-data`,
          },
          {
            nodeName: "H3",
            title: "Shopify data security",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/data-security`,
          },
          {
            nodeName: "H3",
            title: "Shopify app metafields and metaobjects",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/metafields-metaobjects`,
          },
          {
            nodeName: "H3",
            title: "Shopify app frontends",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/frontends`,
          },
          {
            nodeName: "H3",
            title: "Shopify app Oauth",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/oauth`,
          },
          {
            nodeName: "H3",
            title: "Shopify app billing",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/billing`,
          },
          {
            nodeName: "H3",
            title: "Building Shopify extensions",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/extensions`,
          },
          {
            nodeName: "H3",
            title: "Customer account authentication & customer account UI extensions",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/customer-account-ui-extensions`,
          },
          {
            nodeName: "H3",
            title: "Building with Shopify Polaris",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/polaris`,
          },
          {
            nodeName: "H3",
            title: "Store admin connection",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/admin-connection`,
          },
          {
            nodeName: "H3",
            title: "Shopify API version changelog",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/shopify/api-version-changelog`,
          },
        ],
      },
      {
        nodeName: "H2" as const,
        title: "BigCommerce",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/bigcommerce`,
        subNav: [
          {
            nodeName: "H3" as const,
            title: "BigCommerce webhook subscriptions",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/bigcommerce/webhooks`,
          },
          {
            nodeName: "H3" as const,
            title: "Working with BigCommerce data",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/bigcommerce/data`,
          },
          {
            nodeName: "H3" as const,
            title: "Building single-click app frontends",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/bigcommerce/frontends`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "OpenAI",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/openai`,
        subNav: [
          {
            nodeName: "H2",
            title: "Building with OpenAI",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/openai/building-ai-apps`,
          },
        ],
      },
      {
        nodeName: "H2",
        title: "Sentry",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/sentry`,
      },
      {
        nodeName: "H2",
        title: "Authentication",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication`,
        subNav: [
          {
            nodeName: "H2",
            title: "Helpers",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication/helpers`,
          },
          {
            nodeName: "H2",
            title: "Workflows",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication/workflows`,
          },
          {
            nodeName: "H2",
            title: "Building with Google OAuth",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication/google-oauth`,
          },
          {
            nodeName: "H2",
            title: "Building with Email/Password",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication/email-pass`,
          },
          {
            nodeName: "H2",
            title: "Removing default authentication methods",
            itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/plugins/authentication/remove-default-auth-methods`,
          },
        ],
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Environments",
    icon: <EnvironmentsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/environments`,
    subNav: [
      {
        nodeName: "H1",
        title: "Deployment",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/environments/deployment`,
      },
      {
        nodeName: "H1",
        title: "Set up a CI/CD pipeline",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/environments/ci-cd`,
      },
    ],
  },
  {
    nodeName: "H1",
    title: "Source-control",
    icon: <SourceControlDocsIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/source-control`,
  },
  {
    nodeName: "H1",
    title: "Development tools",
    icon: <ToolsDocIcon />,
    itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools`,
    subNav: [
      {
        nodeName: "H1",
        title: "Access control",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/access-control`,
      },
      {
        nodeName: "H2",
        title: "Logger",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/logger`,
      },
      {
        nodeName: "H2",
        title: "GGT",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/cli`,
      },
      {
        nodeName: "H2",
        title: "Environment variables",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/environment-variables`,
      },
      {
        nodeName: "H1",
        title: "Unit testing",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/unit-testing`,
      },
      {
        nodeName: "H1",
        title: "Storing files",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/storing-files`,
      },
      {
        nodeName: "H1",
        title: "Keyboard shortcuts",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/development-tools/keyboard-shortcuts`,
      },
      {
        nodeName: "H1",
        title: "Account and billing",
        itemId: `${MultiEnvironmentDocsVersion.prefix}/guides/account-and-billing`,
      },
    ],
  },
];

const GuidesNav = (location: string, flags: GadgetFlags, currentApp: FullMetaBlob): GadgetItemProps => {
  const currentVersion = currentDocsVersion(location, currentApp);
  const subNav =
    currentVersion.id == "multi-env" ? MutliEnvGuidesHeadings(flags, currentApp) : ActionsAsCodeGuidesHeadings(flags, currentApp);

  return {
    title: "Guides",
    itemId: "/guides",
    testIsActive: (location) => {
      const path = location.split("#")[0];
      return path.startsWith("/guides") || DocsVersions.some((version) => version.prefix != "" && path.startsWith(version.prefix));
    },
    subNav,
  };
};

const ReferenceNav = (
  location: string,
  flags: GadgetFlags,
  currentApp: FullMetaBlob,
  environmentName?: LegacyEnvironmentName
): GadgetItemProps => {
  return {
    title: "Reference",
    itemId: "/reference",
    testIsActive: (location) => location.startsWith("/reference"),
    subNav: [
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react</Code>,
        itemId: "/reference/react",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react/auto</Code>,
        itemId: "/reference/react/auto",
      },
      {
        nodeName: "H1",
        title: <Code>gadget-server</Code>,
        itemId: "/reference/gadget-server",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/auth</Code>,
        itemId: "/reference/auth",
      },
      {
        nodeName: "H1",
        title: "Gelly",
        itemId: "/reference/gelly",
      },
      {
        nodeName: "H1",
        title: "ggt",
        itemId: "/reference/ggt",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react-shopify-app-bridge</Code>,
        itemId: "/reference/shopify-app-bridge",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/shopify-extensions</Code>,
        itemId: "/reference/shopify-extensions",
      },
      {
        nodeName: "H1",
        title: <Code>@gadgetinc/react-bigcommerce</Code>,
        itemId: "/reference/react-bigcommerce",
      } as GadgetItemProps,
    ],
  };
};

type CurrentApp = Partial<FullMetaBlob> & Pick<FullMetaBlob, "name" | "slug" | "canonicalRenderURL" | "models">;

export const ApiReferenceNav = (_flags: GadgetFlags, currentApp: CurrentApp): GadgetItemProps & { subNav?: PageLevelHeading[] } => {
  const apiReferenceBase = `/api/${currentApp.slug}/${currentApp.environment?.slug}`;

  const templateArchitectureNav: NavItem[] & PageLevelHeading[] = [
    {
      title: `Template architecture`,
      itemId: `${apiReferenceBase}/template-architecture`,
      nodeName: "H1",
    },
  ];

  const templateSetupNav: NavItem[] & PageLevelHeading[] = [
    { title: `Template setup`, itemId: `${apiReferenceBase}/template-setup`, nodeName: "H1" },
  ];

  return {
    title: `API`,
    itemId: apiReferenceBase,
    subNav: [
      {
        title: `API Overview`,
        itemId: apiReferenceBase,
        nodeName: "H1",
      },
      ...(currentApp.hasTemplateConfiguration ? templateArchitectureNav : []),
      ...(currentApp.hasTemplateSetup ? templateSetupNav : []),
      {
        title: `API calls within Gadget`,
        itemId: `${apiReferenceBase}/api-calls-within-gadget`,
        nodeName: "H1",
      },
      {
        title: "External API calls with Gadget",
        itemId: `${apiReferenceBase}/external-api-calls`,
        nodeName: "H1",
        subNav: [
          {
            nodeName: "H1",
            title: "Installing",
            itemId: `${apiReferenceBase}/external-api-calls/installing`,
          },
          {
            nodeName: "H1",
            title: "Authentication",
            itemId: `${apiReferenceBase}/external-api-calls/authentication`,
          },
          {
            nodeName: "H1",
            title: "GraphQL",
            itemId: `${apiReferenceBase}/external-api-calls/graphql`,
          },
        ],
      },
      {
        nodeName: "H1",
        title: "Record",
        itemId: `${apiReferenceBase}/gadget-record`,
      },
      {
        nodeName: "H1",
        title: "Internal vs Public API",
        itemId: `${apiReferenceBase}/internal-api`,
      },
      {
        nodeName: "H1",
        title: "Sorting and filtering",
        itemId: `${apiReferenceBase}/sorting-and-filtering`,
      },
      {
        nodeName: "H1",
        title: "Errors",
        itemId: `${apiReferenceBase}/errors`,
      },
      ...SchemaSection(apiReferenceBase, currentApp),
    ],
  };
};

const SchemaSection = (apiReferenceBase: string, currentApp: CurrentApp): GadgetItemProps[] => {
  const schemaSection = [
    {
      title: "Schema",
      itemId: `${apiReferenceBase}/schema`,
      disabled: true,
      onlyHeader: GadgetOnlyHeader.Header,
    },
    ...currentApp.models.map((model) => {
      const namespacedApiidentifier = getNamespacedApiIdentifier(model.namespace, model.apiIdentifier);
      return {
        title: namespacedApiidentifier,
        itemId: `${apiReferenceBase}/schema/${namespacedApiidentifier}`,
        nodeName: "H1" as PageLevelHeading["nodeName"],
      };
    }),
  ];

  if (currentApp.hasGlobalActions) {
    schemaSection.push({
      title: "Global actions",
      itemId: `${apiReferenceBase}/schema/global-actions`,
      nodeName: "H1",
    });
  }

  return schemaSection;
};

/** Returns a list of nested nav items in baseui format */
export const DocsNavItems = (location: string, flags: GadgetFlags, currentApp: FullMetaBlob): GadgetItemProps[] => {
  return compact([
    GuidesNav(location, flags, currentApp),
    ApiReferenceNav(flags, currentApp),
    ReferenceNav(location, flags, currentApp),
    {
      itemId: "/assistant",
      // eslint-disable-next-line react/jsx-no-undef
      title: (
        <Row $gap="6px">
          <SparkleIcon /> Assistant
        </Row>
      ),
    },
  ]);
};
